
import { defineComponent, reactive, ref, onMounted } from "vue";
import { Pager } from "@/types/Pager";
import { Invoice } from "@/types/Invoice";
import { apiAllInvoices } from "@/api/invoices";
import { convertMoney } from "@/common/helper";
import { DollarOutlined, PrinterOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Invoices",
  components: {
    DollarOutlined,
    PrinterOutlined,
  },
  setup() {
    const invoices: Invoice[] = reactive([]);
    const pagination: Pager = reactive({
      total: 1,
      current: 1,
      pageSize: 15,
    });
    const isLoading = ref(false);
    const columns = [
      {
        title: "Item",
        key: "database",
        dataIndex: "database",
        slots: { customRender: "database" },
      },
      {
        title: "Total",
        key: "total",
        dataIndex: "total",
        slots: { customRender: "total" },
      },
      {
        title: "Created",
        key: "createdAt",
        dataIndex: "createdAt",
        slots: { customRender: "createdAt" },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        slots: { customRender: "status" },
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "20%",
        slots: { customRender: "action" },
      },
    ];

    const loadInvoices = async (page = 1) => {
      isLoading.value = true;
      const { data } = await apiAllInvoices(page);
      invoices.splice(0, invoices.length, ...data.data);
      isLoading.value = false;
      pagination.current = data.currentPage;
      pagination.pageSize = data.perPage;
      pagination.total = data.total;
    };

    onMounted(loadInvoices);

    const handleTableChange = (paginationValue: Pager) => {
      const pager: Pager = { ...pagination };
      pager.current = paginationValue.current;
      Object.assign(pagination, pager);
      if (pager.current) {
        loadInvoices(pager.current);
      }
    };

    return {
      convertMoney,
      handleTableChange,
      invoices,
      isLoading,
      columns,
      pagination,
    };
  },
});
